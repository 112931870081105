<template>
  <div class="c-prev-next-nav">
    <a v-if="prev" class="c-prev-next-nav__link c-prev-next-nav__link--prev" :href="prev.to">
      <NextArrow class="c-prev-next-nav__icon-arrow" />
      {{ prev.title }}
    </a>
    <a v-if="next" class="c-prev-next-nav__link c-prev-next-nav__link--next" :href="next.to">
      {{ next.title }}
      <NextArrow class="c-prev-next-nav__icon-arrow" />
    </a>
  </div>
</template>

<script>
import NextArrow from '@/assets/svg/NextArrow'

export default {
  name: 'PrevNextNav',

  components: {
    NextArrow
  },

  props: {
    next: Object,
    prev: Object
  }
}
</script>

<style lang="scss">
.c-prev-next-nav {
  display: flex;
  margin-right: 1rem;
}
.c-prev-next-nav__link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: $color-black;
  font-family: 'Circular Pro', sans-serif;
  font-size: 0.85rem;

  &--next {
    margin-left: 1rem;

    .c-prev-next-nav__icon-arrow {
      margin-left: 0.5rem;
    }
  }

  &--prev {
    .c-prev-next-nav__icon-arrow {
      transform: rotate(180deg);
      margin-right: 0.5rem;
    }
  }
}
</style>