<template>
  <div :class="[ 'c-activity', `c-activity--${parentUnit}` ]">
    <div class="c-activity__top-wrapper">
      <FHBreadcrumbs v-if="crumbs"
        class="c-activity__breadcrumbs u-py u-px"
        :crumbs="crumbs" />
      <PrevNextNav :prev="prevActivity" :next="nextActivity" />
    </div>
    <VHeading header="h1" class="c-activity__title o-wrapper o-heading">
      {{ activityType.name }}
    </VHeading>
    <div class="c-activity__container o-wrapper u-py u-px u-px--3">
      <TheTextBox class="o-wrapper u-mr--2" :exercise="exerciseText"
        :goal="goalText" />
      <div class="c-activity__goals u-py">
        <Goal v-for="goal in activityType.LearningGoals"
          class="c-activity__single-goal"
          :key="goal._id"
          :title="goal.Label"
          :content="goal.Content" />
      </div>
    </div>

    <FileElement class="c-activity__file o-wrapper u-px u-px--3 u-pb--2"
      v-for="file in files"
      :key="file.id"
      :content="file" />

    <FHTabs
      v-if="articles.length"
      ref="tabs"
      :tabable="true"
      @changed="onChangeTab">
      <template #before-tablist>
        <div class="c-tabs__tablist-label">
          {{ $t('ACTIVITY_TABS_LABEL') }}:
        </div>
      </template>
      <FHTab v-for="article in activityType.associations"
        :key="article._id"
        :name="article.name">
        <component
          :is="componentNameNormalize(article.__component)"
          :article="article.article_type"
          class="c-tab__article"></component>
      </FHTab>
    </FHTabs>
    <Modal />
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { fetchWithAuth, fetchSH } from '@/api'
import { fetchLesson, fetchUnitBySlug } from '@/services/strapi'
import { componentNameNormalize } from '@/utils'
import VHeading from '@forlagshuset/v-heading'
import { ContentLoader } from 'vue-content-loader'
import { FHTabs, FHTab } from '@forlagshuset/v-tabs'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'
import TheTextBox from '@/components/TheTextBox'
import FileElement from '@/components/FileElement'
import ComponentLearningElementArticleElement from '@/components/ArticleWrapper'
import Goal from '@/components/Goal'
import PrevNextNav from '@/components/PrevNextNav'
import { parseLearningElements } from '@/utils'
import Modal from '@/components/Modal'

export default {
  name: 'Activity',

  components: {
    PrevNextNav,
    FHBreadcrumbs,
    VHeading,
    FHTabs,
    FHTab,
    TheTextBox,
    ComponentLearningElementArticleElement,
    FileElement,
    ContentLoader,
    Goal,
    Modal
  },

  setup(props, ctx) {
    const activityTypeSlug = computed(() => ctx.root.$options.router.history.current.params.activityTypeSlug)
    const unitSlug = computed(() => ctx.root.$options.router.history.current.params.unitSlug)
    const lessonSlug = computed(() => ctx.root.$options.router.history.current.params.lessonSlug)
    const activityType = ref({})
    const lesson = ref(null)
    const exerciseText = ref('')
    const goalText = ref('')
    const parentUnit = ref('')
    const onChangeTab = ref(() => {})
    const crumbs = ref([])
    const learningGoals = ref([])
    const nextActivity = ref(null)
    const prevActivity = ref(null)
    const tabs = ref(null)
    const articles = ref([])
    const files = ref([])
    const tag = ref({})

    if (!ctx.root.$options.router.history.current.query.tab) {
      onChangeTab.value = selectedTab => {
        ctx.root.$router.push({query: {tab: selectedTab.tab.$el.id}})
      }
    }

    onMounted(async () => {
      activityType.value = await (await fetchWithAuth('activity-types/' + activityTypeSlug.value)).json()
      learningGoals.value = activityType.LearningGoals
      exerciseText.value = activityType.value.content[0].body
      goalText.value = activityType.value.content[1].content
      articles.value = activityType.value.associations.filter(content => componentNameNormalize(content.__component) === 'ComponentLearningElementArticleElement')
      files.value = activityType.value.associations.filter(content => componentNameNormalize(content.__component) === 'ComponentLearningElementFileElement')

      const unit = await fetchUnitBySlug(unitSlug.value)
      parentUnit.value = unit ? unit.parent.slug : ''

      lesson.value = await fetchLesson(lessonSlug.value)

      if (ctx.root.$options.router.history.current.query.tab) {
        ctx.root.$nextTick(() => {
          ctx.refs.tabs.setActive(`#${ctx.root.$options.router.history.current.query.tab}`)
          onChangeTab.value = selectedTab => {
            ctx.root.$router.push({query: {tab: selectedTab.tab.$el.id}})
            tabs.value.$el.scrollIntoView({behavior: "smooth"})
          }
        })
      }
      if(unit) {
        crumbs.value.push({
          type: 'unit',
          name: unit.name,
          slug: unit.slug
        })
      }

      if(lesson.value) {
        crumbs.value.push({
          type: 'unit',
          name: lesson.value.name,
          slug: unit.slug + '?tab=' + lesson.value.slug
        })
      }

      crumbs.value.push({
        type: 'activity',
        name: activityType.value.name,
        slug: activityTypeSlug.value
      })

      const studentActivities = parseLearningElements(lesson.value.learningElementSection).filter(les => les.items && les.items[0].__component === 'learning-element.activity-element')
      const teacherActivities = parseLearningElements(lesson.value.scenario).filter(les => les.items && les.items[0].__component === 'learning-element.activity-element')

      const searchForActivityCollection = () => {
        let found = false

        studentActivities.forEach(sa => {
          sa.items.forEach((saItem, i) => {
            if (found) return
            if (saItem.activity_type.slug === activityTypeSlug.value) {
              found === true
              const prev = sa.items[i - 1]
              const next = sa.items[i + 1]

              if (prev) {
                prevActivity.value = {
                  title: prev.name,
                  to: `/activity/${unitSlug.value}/${lessonSlug.value}/${prev.activity_type.slug}`
                }
              }
              if (next) {
                nextActivity.value = {
                  title: next.name,
                  to: `/activity/${unitSlug.value}/${lessonSlug.value}/${next.activity_type.slug}`
                }
              }
            }
          })
        })
        if (found) return
        teacherActivities.forEach(sa => {
          sa.items.forEach((saItem, i) => {
            if (found) return
            if (saItem.activity_type.slug === activityTypeSlug.value) {
              found === true
              const prev = sa.items[i - 1]
              const next = sa.items[i + 1]

              if (prev) {
                prevActivity.value = {
                  title: prev.name,
                  to: `/activity/${unitSlug.value}/${lessonSlug.value}/${prev.activity_type.slug}`
                }
              }
              if (next) {
                nextActivity.value = {
                  title: next.name,
                  to: `/activity/${unitSlug.value}/${lessonSlug.value}/${next.activity_type.slug}`
                }
              }
            }
          })
        })
        if (!found) {
          //activity in tags
          activityType.value.tags.forEach(async t => {
            if(t.label === lesson.value.name) {
              tag.value = await fetchSH('tag-types/' + t.id)
              tag.value.activities.forEach((a, i) => {
                if (found) return
                if (a.slug === activityTypeSlug.value) {
                  found === true
                  const prev = tag.value.activities[i - 1]
                  const next = tag.value.activities[i + 1]

                  if (prev) {
                    prevActivity.value = {
                      title: prev.name,
                      to: `/activity/${unitSlug.value}/${lessonSlug.value}/${prev.slug}`
                    }
                  }
                  if (next) {
                    nextActivity.value = {
                      title: next.name,
                      to: `/activity/${unitSlug.value}/${lessonSlug.value}/${next.slug}`
                    }
                  }
                }
              })
            }
          })
        }
      }
      searchForActivityCollection()
    })

    return {
      parentUnit,
      lesson,
      activityType,
      exerciseText,
      goalText,
      componentNameNormalize,
      onChangeTab,
      crumbs,
      learningGoals,
      prevActivity,
      nextActivity,
      tabs,
      files,
      articles,
      tag
    }
  }
}
</script>

<style lang="scss">
.c-activity {
  min-height: calc(100vh - 17rem);
  &[class*="c-activity--aktor-parent-1"] {
    background: $unit-1-color-brand-light;
    color: $color-black;
    .c-textbox__exercise,
    .c-tabs__btn--active,
    .c-tabs__btn--active:before,
    .c-activity-element__btn,
    .c-text-box-element--factbox,
    .c-text-box-element--speechbubble p:first-of-type,
    .c-text-box-element--speechbubble p:first-of-type:before,
    .c-text-box-element--discuss {
      background: $unit-1-color-brand;
    }
    .c-tabs__btn, .c-activity-element__btn {
      border-color: $unit-1-color-brand;
      &:hover {
        background: $unit-1-color-brand;
      }
    }
    .c-text-box-element--against {
      background: $unit-1-color-brand-light;
    }
  }
  &[class*="c-activity--aktor-parent-2"] {
    background: $unit-2-color-brand-light;
    color: $color-black;
    .c-textbox__exercise,
    .c-tabs__btn--active,
    .c-tabs__btn--active:before,
    .c-activity-element__btn,
    .c-text-box-element--factbox,
    .c-text-box-element--speechbubble p:first-of-type,
    .c-text-box-element--speechbubble p:first-of-type:before,
    .c-text-box-element--discuss {
      background: $unit-2-color-brand;
    }
    .c-tabs__btn, .c-activity-element__btn {
      border-color: $unit-2-color-brand;
      &:hover {
        background: $unit-2-color-brand;
      }
    }
    .c-text-box-element--against {
      background: $unit-2-color-brand-light;
    }
  }
  &[class*="c-activity--aktor-parent-3"] {
    background: $unit-3-color-brand-light;
    color: $color-black;
    .c-textbox__exercise,
    .c-tabs__btn--active,
    .c-tabs__btn--active:before,
    .c-activity-element__btn,
    .c-text-box-element--factbox,
    .c-text-box-element--speechbubble p:first-of-type,
    .c-text-box-element--speechbubble p:first-of-type:before,
    .c-text-box-element--discuss {
      background: $unit-3-color-brand;
      color: $color-black;
    }
    .c-tabs__btn, .c-activity-element__btn {
      border-color: $unit-3-color-brand;
      color: $color-black;
      &:hover {
        background: $unit-3-color-brand;
      }
    }
    .c-text-box-element--against {
      background: $unit-3-color-brand-light;
    }
  }
}
.c-activity__breadcrumbs {
  font-family: CircularPro, sans-serif;
  position: relative;
  z-index: 1;
  .c-breadcrumbs__home {
    display: none;
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}
.c-activity__container {
  display: flex;
  max-width: $xlarge;
  margin-left: auto;
  margin-right: auto;
}
.c-activity__title {
  h1 {
    margin: 0;
  }
}

.c-activity__top-wrapper {
  display: flex;
  justify-content: space-between;
}

.c-tabs__btn {
  &--active {
    background-color: $color-white;
    border-color: $color-white;
    &:before {
      background-color: $color-white;
    }
  }
}

.c-activity__file {
  .c-file-element__link {
    background-color: $color-red;
    color: #fff;
    border-radius: 2rem;
    font-family: CircularPro, sans-serif;
    line-height: 1.5;
  }
}
</style>
