<template>
  <div class="c-file-element">
    {{ $t('DOWNLOAD_TEXT') }}
    <FHButton
      :href="content.file_type.file.url"
      class="c-file-element__link u-mx"
      @click.native.prevent="downloadFile(content.file_type.file)"
    >
      {{ $t('DOWNLOAD_BUTTON') }}
    </FHButton>
  </div>
</template>

<script>
import FHButton from '@forlagshuset/v-button'
import { saveAs } from 'file-saver'

export default {
  props: {
    content: Object
  },

  components: {
    FHButton
  },

  methods: {
    async downloadFile(file) {
      const fileExternal = await fetch(file.url)
      const blob = await fileExternal.blob()

      saveAs(blob, file.name)
    }
  }
}
</script>
